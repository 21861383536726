import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Col, Layout, List, Row } from "antd";
import { RightCircleOutlined } from "@ant-design/icons";

import Header from "../components/Header";

import BackendClient from "../clients/backendClient";
import { DynamicForm, Tenant } from "../types/DynamicForm";

const TenantPage: React.FC = () => {
  const [tenant, setTenant] = useState<Tenant>({} as Tenant);
  const [forms, setForms] = useState<Array<DynamicForm>>([]);

  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const pathArray = pathname.split("/");
    BackendClient.getTenant(pathArray[pathArray.length - 1]).then(
      (response) => {
        setTenant(response);
        BackendClient.getFormsForTenant(pathArray[pathArray.length - 1]).then(
          (response) => {
            setForms(response);
          }
        );
      }
    );
  }, [pathname]);

  return (
    <div>
      <Header tenant={tenant} />
      <Layout style={{ backgroundColor: "#fff", margin: "2rem" }}>
        <Row>
          <Col md={24} lg={12} style={{ margin: "0 auto" }}>
            <List
              size="large"
              itemLayout="horizontal"
              dataSource={forms}
              renderItem={(x: DynamicForm) => {
                const formURL = `/form/${x.id}`;

                return (
                  <List.Item
                    actions={[
                      <RightCircleOutlined onClick={() => navigate(formURL)} />,
                    ]}
                  >
                    <List.Item.Meta
                      title={<NavLink to={formURL}>{x.name}</NavLink>}
                      description={t("tenant.lastUpdate", {
                        date: x.update_date,
                      })}
                    />
                  </List.Item>
                );
              }}
            />
          </Col>
        </Row>
      </Layout>
    </div>
  );
};

export default TenantPage;
