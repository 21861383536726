import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Layout, Row, Col } from "antd";
import {
  RedoOutlined,
  PicCenterOutlined,
  PicRightOutlined,
} from "@ant-design/icons";

import "iframe-resizer/js/iframeResizer.min";

import Header from "../components/Header";

import BackendClient from "../clients/backendClient";
import { DynamicForm, DynamicFormDisplayMode } from "../types/DynamicForm";

const ViewerUrl = process.env.REACT_APP_FORMOL_URL as string;
const frameId = "dynamidFrame";

const FormPage: React.FC = () => {
  const [form, setForm] = useState<DynamicForm>({} as DynamicForm);
  const [isReady, setIsReady] = useState(false);
  const [displayMode, setDisplayMode] = useState(
    DynamicFormDisplayMode.Standard
  );

  const {
    i18n: { language },
  } = useTranslation();

  const { pathname } = useLocation();
  const formContainerRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const pathArray = pathname.split("/");
    BackendClient.getForm(pathArray[pathArray.length - 1]).then((response) => {
      setForm(response);
    });
  }, [pathname]);

  useEffect(() => {
    if (!formContainerRef?.current?.contentWindow) {
      return;
    }

    setTimeout(() => {
      formContainerRef?.current?.contentWindow?.postMessage(
        {
          type: "DYNAMIC_FORM_PAYLOAD",
          payload: form.data,
          isPartialDisplay: false,
          devMode: false,
          language: language,
          displayMode: displayMode,
        },
        ViewerUrl
      );
    }, 500);
  }, [isReady, form, language, displayMode]);

  useEffect(() => {
    window.addEventListener("message", handleFormMessages);

    return () => {
      window.removeEventListener("message", handleFormMessages);
    };
  }, []);

  useEffect(() => {
    window.iFrameResize({ log: false }, `#${frameId}`);

    return () => {
      const frame = document.querySelector(`#${frameId}`);
      if (frame) {
        (frame as any).iFrameResizer.close();
      }
    };
  }, []);

  const handleFormMessages = async (event: MessageEvent) => {
    const {
      data: { type },
    } = event;

    switch (type) {
      case "DYNAMIC_FORM_READY":
        setIsReady(true);
        break;
      case "DYNAMIC_FORM_NAVIGATE_TO":
        formContainerRef?.current?.scrollIntoView();
        break;
      default:
    }
  };

  const resetForm = () => {
    if (!formContainerRef?.current?.contentWindow) {
      return;
    }

    formContainerRef?.current?.contentWindow?.postMessage(
      {
        type: "DYNAMIC_FORM_RESET",
      },
      ViewerUrl
    );
  };

  const toggleDisplayMode = () => {
    setDisplayMode(
      displayMode === DynamicFormDisplayMode.Standard
        ? DynamicFormDisplayMode.Sectional
        : DynamicFormDisplayMode.Standard
    );
  };

  const icon =
    displayMode === DynamicFormDisplayMode.Standard ? (
      <PicRightOutlined />
    ) : (
      <PicCenterOutlined />
    );

  const viewerWidth = displayMode === DynamicFormDisplayMode.Standard ? 12 : 18;

  return (
    <div>
      <Header
        form={form}
        tenant={form.tenant}
        extra={[
          <Button
            type="primary"
            shape="round"
            size={"large"}
            icon={icon}
            onClick={toggleDisplayMode}
          />,
          <Button
            type="primary"
            shape="round"
            size={"large"}
            icon={<RedoOutlined />}
            onClick={resetForm}
          />,
        ]}
      />
      <Layout style={{ backgroundColor: "#fff", margin: "2rem" }}>
        <Row>
          <Col md={24} lg={viewerWidth} style={{ margin: "0 auto" }}>
            <iframe
              style={{ width: "100%", height: "100%", border: "none" }}
              id={frameId}
              ref={formContainerRef}
              src={ViewerUrl}
              title={form.id}
            />
          </Col>
        </Row>
      </Layout>
    </div>
  );
};

export default FormPage;
