declare global {
  interface Window {
    iFrameResize: any;
  }
}

export type Tenant = {
  id: string;
  name: string;
};

export type DynamicForm = {
  id: string;
  name: string;
  update_date: Date;
  tenant: Tenant;
  data: object;
};

export enum DynamicFormDisplayMode {
  Standard = "standard",
  Sectional = "sectional",
  Conversational = "conversational"
}
