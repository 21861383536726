import { Routes, Route } from "react-router-dom";

import FormPage from "./pages/FormPage";
import TenantPage from "./pages/TenantPage";

function App() {
  return (
    <Routes>
      <Route path="/form/:id" element={<FormPage />} />
      <Route path="/tenant/:id" element={<TenantPage />} />
    </Routes>
  );
}

export default App;
