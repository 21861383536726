import React, { ReactNode } from "react";
import { Affix, Button, Breadcrumb, PageHeader } from "antd";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AkinoxLogo from "../images/Akinox.svg";

import { DynamicForm, Tenant } from "../types/DynamicForm";

const Header: React.FC<{
  tenant: Tenant;
  form?: DynamicForm;
  extra?: Array<ReactNode>;
}> = ({ tenant, form, extra }) => {
  const {
    i18n: { changeLanguage, language },
  } = useTranslation();
  const tenantURL = `/tenant/${tenant?.id}`;

  const toggleLanguage = () => {
    changeLanguage(language === "en" ? "fr" : "en");
  };

  return (
    <Affix>
      <PageHeader
        title={
          <img
            style={{
              width: "100%",
              maxHeight: "35px",
              verticalAlign: "unset",
            }}
            src={AkinoxLogo}
            alt="Akinox"
          />
        }
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to={tenantURL}>{tenant?.name}</NavLink>
            </Breadcrumb.Item>
            {form && <Breadcrumb.Item>{form.name}</Breadcrumb.Item>}
          </Breadcrumb>
        }
        style={{ backgroundColor: "#fff", borderBottom: "2px solid #000" }}
        extra={[
          ...(extra as Array<ReactNode>),
          <Button
            type="primary"
            shape="round"
            size={"large"}
            onClick={toggleLanguage}
          >
            {language === "en" ? "Français" : "English"}
          </Button>,
        ]}
      />
    </Affix>
  );
};

Header.defaultProps = {
  extra: [],
};

export default Header;
