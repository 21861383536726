import { DynamicForm, Tenant } from "../types/DynamicForm";

const HEADER_REQUESTED_WITH: string = "X-Requested-With";
const HEADER_CONTENT_TYPE: string = "Content-Type";

class BackendClient {
  protected _url: string;

  constructor() {
    this._url = (process.env.REACT_APP_BACKEND_URL as string) || "";
  }

  setUrl(url: string): void {
    this._url = url;
  }

  getForm = async (id: string): Promise<DynamicForm> =>
    this.doFetch<DynamicForm>(`${this.getV1Route()}/form/${id}`, {
      method: "get",
    });

  getFormsForTenant = async (id: string): Promise<Array<DynamicForm>> =>
    this.doFetch<Array<DynamicForm>>(`${this.getV1Route()}/tenant/${id}/form`, {
      method: "get",
    });

  getTenant = async (id: string): Promise<Tenant> =>
    this.doFetch<Tenant>(`${this.getV1Route()}/tenant/${id}`, {
      method: "get",
    });

  private getV1Route(): string {
    return `${this._url}/api/v1`;
  }

  private getOptions(options: object): RequestInit {
    const newOptions: RequestInit = Object.assign({}, options);

    const headers: HeadersInit = {
      [HEADER_CONTENT_TYPE]: "application/json",
      [HEADER_REQUESTED_WITH]: "XMLHttpRequest",
    };

    if (newOptions.headers) {
      Object.assign(headers, newOptions.headers);
    }

    return {
      ...newOptions,
      headers,
    };
  }

  private getInvalidResponseError(url: string, err: any): object {
    return {
      message: "Received invalid response from the server.",
      details: JSON.stringify(err),
      url,
    };
  }

  private async doFetch<T>(url: string, options: RequestInit): Promise<T> {
    const response = await fetch(url, this.getOptions(options));

    let data: any;
    {
      try {
        data = await response.json();
      } catch (err) {
        throw this.getInvalidResponseError(url, err);
      }

      if (response.ok) {
        return data;
      }

      const msg = data?.message || data?.error || "";

      throw {
        message: msg,
        server_error_id: data.id,
        status_code: response.status,
        url,
      };
    }
  }
}

export default new BackendClient();
